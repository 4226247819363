import React from 'react';
import { graphql } from 'gatsby';
import GameCreationRoot from '../../components/game-creation-root';
import BeQuestionsForm from '../../components/game-forms/be-questions-form';

const QuestionsGamePage = (props) => {
	return(
        <GameCreationRoot title={"Questions"}>
            <BeQuestionsForm />
        </GameCreationRoot>
	);
}

export default QuestionsGamePage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "side_bar", "games", "experiment", "form_errors"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;